import React, { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
//import 'react-circular-progressbar/dist/styles.css';
import { PauseIcon, PlayIcon, ArrowPathIcon, CogIcon } from '@heroicons/react/24/solid';
import Settings from '../Settings/Settings';
import './TimerStyles.css';
import './ProgressBar.css';
import { useTimer } from 'react-use-precision-timer';
import Footer from '../Footer/Footer';

function Timer(){

    document.title = 'ProducTimer'
    /***************** WORK ********************/
    const [workCurrentMode, setWorkCurrentMode] = useState(true);
    const [workIsActive, setWorkIsActive] = useState(false);
    const [workTime, setWorkTime] = useState(45); //number of work minutes
    const [workTotalSeconds, setWorkTotalSeconds] = useState(workTime * 60);
    const [workSeconds, setWorkSeconds] = useState(0);
    const [workMinutes, setWorkMinutes] = useState(workTime);
    
    /***************** BREAK *******************/
    const [breakCurrentMode, setBreakCurrentMode] = useState(false);
    const [breakIsActive, setBreakIsActive] = useState(false);
    const [breakTime, setBreakTime] = useState(15); //number of break minutes
    const [breakTotalSeconds, setBreakTotalSeconds] = useState(breakTime * 60);
    const [breakSeconds, setBreakSeconds] = useState(0);
    const [breakMinutes, setBreakMinutes] = useState(45);
    const timer = useTimer({ delay: 1000, callback: () => doTimer()});
    //const breakTimer = useTimer({ delay: 1000, callback: () => doBreak()});

    const [settingsActive, setSettingsActive] = useState(false); //boolean to trigger the opening of the settings page

    //values that settings sliders set
    
    const [seconds, setSeconds] = useState(0); //seconds to display on the timer
    const [minutes, setMinutes] = useState(45); //minutes to display on the timer
    const maxWorkSeconds = (workTime * 60); 
    const maxBreakSeconds = (breakTime * 60);
    const [totalSeconds, setTotalSeconds] = useState(maxWorkSeconds); //quantity used by the circular progress bar
    
    
    var workString;

    if (workSeconds < 10){
        workString = `${workMinutes}:0${workSeconds}`;
    }else{
        workString = `${workMinutes}:${workSeconds}`;
    }


    var breakString;
    
    if (breakSeconds < 10){
        breakString = `${breakMinutes}:0${breakSeconds}`;
    }else{
        breakString = `${breakMinutes}:${breakSeconds}`;
    }


    const BLUE = '#3e98c7';
    const RED = '#C84630';
    const [progressbarColor, setProgressBarColor] = useState(BLUE);
    var timeString;

    if (workCurrentMode){
        timeString = workString;
    }else if (breakCurrentMode){
        timeString = breakString;
    }

    

    

    





    // }else if (breakCurrentMode){
    //     if (breakSeconds < 10){
    //         displayString = `${breakMinutes}:0${breakSeconds}`
    //     }else{
    //         displayString = `${breakMinutes}:${breakSeconds}`
    //     }
        
    //     setBreakTotalSeconds(breakTime * 60);
    //     setBreakMinutes(breakTime);
    //     setBreakSeconds(0);
    //     setProgressBarColor(RED);
    // }

    function doTimer(){
        if(workCurrentMode){
            if (workIsActive){
                if (workSeconds === 0){
                    setWorkMinutes(workMinutes - 1);
                    setWorkSeconds(59);
                }else if (workTotalSeconds == 6 || workTotalSeconds == 0){
                    pauseWork();
                    setWorkIsActive(false);
                    setWorkCurrentMode(false);
                    setBreakCurrentMode(true);
                    setBreakIsActive(true);
                    setBreakTotalSeconds(breakTime * 60);
                    setBreakMinutes(breakTime);
                    setBreakSeconds(0);
                    setProgressBarColor(RED);
                    pauseBreak();
                    resetBreak();
                    breakString = `${breakMinutes}:0${breakSeconds}`;
                }else if (workString.includes('-')){
                    pauseWork();
                    setWorkIsActive(false);
                    setWorkCurrentMode(false);
                    setBreakCurrentMode(true);
                    setBreakIsActive(true);
                    setBreakTotalSeconds(breakTime * 60);
                    setBreakMinutes(breakTime);
                    setBreakSeconds(0);
                    setProgressBarColor(RED);
                    pauseBreak();
                    resetBreak();
                    breakString = `${breakMinutes}:0${breakSeconds}`;
                }else{
                    setWorkTotalSeconds(workTotalSeconds - 1);
                    setWorkSeconds(workSeconds - 1);
                }
            }
        }else if (breakCurrentMode){
            if (breakIsActive){
                if (breakSeconds === 0){
                    setBreakMinutes(breakMinutes - 1);
                    setBreakSeconds(59);
                }else if (breakTotalSeconds === 6 || breakTotalSeconds == 0){
                    pauseBreak();
                    setBreakIsActive(false);
                    setBreakCurrentMode(false);
                    setWorkCurrentMode(true);
                    setWorkTotalSeconds(workTime * 60);
                    setWorkMinutes(workTime);
                    setWorkSeconds(0);
                    setProgressBarColor(BLUE);
                    resetWork();
                    workString = `${workMinutes}:0${workSeconds}`;
                }else if (breakString.includes('-')){
                    pauseBreak();
                    setBreakIsActive(false);
                    setBreakCurrentMode(false);
                    setWorkCurrentMode(true);
                    setWorkTotalSeconds(workTime * 60);
                    setWorkMinutes(workTime);
                    setWorkSeconds(0);
                    setProgressBarColor(BLUE);
                    resetWork();
                    workString = `${workMinutes}:0${workSeconds}`;
                }else{
                    setBreakTotalSeconds(breakTotalSeconds - 1);
                    setBreakSeconds(breakSeconds - 1);
                }
            }
        }
 
    }

    

    function toggleMaster(){
        if (workCurrentMode){
            if(workIsActive){
                pauseWork();
            }else if (workIsActive === false){
                playWork();
            }            
        }else if (breakCurrentMode){
            if(breakIsActive){
                pauseBreak();
            }else if (breakIsActive === false){
                playBreak();
            }
        }
    }

    function pauseWork(){
        timer.stop();
        setWorkIsActive(false);
    }
    
    function playWork(){
        timer.start();
        setWorkIsActive(true);
    }

    function pauseBreak(){
        timer.stop();
        setBreakIsActive(false);
    }

    function playBreak(){
        timer.start();
        setBreakIsActive(true);
    }






    function resetWork() {
       if (workIsActive){
            pauseWork();
       }
       setWorkMinutes(workTime);
       setWorkSeconds(0);
       setWorkTotalSeconds(workTime*60);
    }


    
    function resetBreak(){
        if (breakIsActive){
            pauseBreak();
        }
        setBreakMinutes(breakTime);
        setBreakSeconds(0);
        setBreakTotalSeconds(breakTime*60);
    }

    function resetMaster(){
        if(workCurrentMode){
            resetWork();
        }else if (breakCurrentMode){
            resetBreak();
        }
    }



    // if (workCurrentMode){
    //     if (workSeconds < 10){
    //         workString = `${workMinutes}:0${workSeconds}`
    //     }else if (workSeconds > 10){
    //         workString = `${workMinutes}:${workSeconds}`
    //     }
    // }else if (breakCurrentMode){
    //     if (breakSeconds < 10){
    //         breakString = `0${breakSeconds}`
    //     }else{
    //         breakString = `${breakSeconds}`
    //     }
    // }




    if (settingsActive){
        return(
            <div className='settingsPage'>
                <Settings 
                    settingsBool={settingsActive} 
                    workTimeFunc={setWorkTime}
                    workMinutes={setWorkMinutes} 
                    workSeconds={setWorkSeconds}
                    workTotalSeconds={setWorkTotalSeconds}
                    breakTimeFunc={setBreakTime}
                    breakMinutes={setBreakMinutes}
                    breakSeconds={setBreakSeconds}
                    breakTotalSeconds={setBreakTotalSeconds} 
                    workTime={workTime}
                    breakTime={breakTime}
                    minutesFunc={setMinutes}
                />
                <button className='settingsButton'>
                    <CogIcon className="settingsImage" onClick={function(event){setSettingsActive(false);}}/>
                </button>
            </div>

        )
    }else{
        return (
            
            <div className="timerPage">
            <div className="progressCircle">
                <CircularProgressbar 
                    styles={buildStyles({
                        pathColor: progressbarColor,
                        textColor: progressbarColor,
                        trailColor: '#d6d6d6',
                        backgroundColor: progressbarColor
                    })}
                    value={workCurrentMode ? workTotalSeconds : breakTotalSeconds} 
                    minValue={0} 
                    maxValue={workCurrentMode ?  (workTime * 60) : (breakTime * 60)} 
                    text={timeString} 
                />
                
            </div>
                    
                    
            <div className="row">
                <button className={'pausePlayButton'} onClick={function(event){toggleMaster();}}>
                {workIsActive || breakIsActive ? <PauseIcon className='pauseImage' /> : <PlayIcon className='playImage'/>}
                </button>
                <button className="resetButton" onClick={function(event){resetMaster();}}>
           
                <ArrowPathIcon className='resetImage'/>
                </button>
                <button className='settingsButton'>
                    <CogIcon className="settingsImage" onClick={function(event){setSettingsActive(!settingsActive); pauseWork(); pauseBreak();}}/>
                </button>
                <Footer/>
                
            </div>
            </div>
        );
    }
};

export default Timer;