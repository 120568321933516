import React, { useContext, useState } from "react";
import { CogIcon, EnvelopeIcon } from "@heroicons/react/24/solid";
import ReactSlider from 'react-slider';
import SettingsStyles from './SettingsStyles.css';

export default function Settings(props){
    
        return(
            <div className="settingsComponentDiv">
                <div>
                    
                    <h4>Work Minutes: {props.workTime}</h4>
                    <div className="workSliderDiv">

                        <ReactSlider 
                            className='workSlider'
                            thumbClassName='workThumb'
                            trackClassName='workTrack'
                            value={props.workTime}
                            onChange={function(event){props.workTimeFunc(event); props.workMinutes(event); props.workSeconds(0); props.workTotalSeconds(event*60)}}
                            min={0}
                            max={45} //should be 45
                            step={5} //should be 5
                            
                            
                            
                            />
                    </div>
                    {/*This is a comment*/}
                    <h4>Break Minutes: {props.breakTime}</h4>
                    <div className="breakSliderDiv">
                        <ReactSlider 
                            className='breakSlider'
                            thumbClassName='breakThumb'
                            trackClassName='breakTrack'
                            value={props.breakTime}
                            onChange={function(event){props.breakTimeFunc(event); props.breakMinutes(event); props.breakSeconds(0); props.breakTotalSeconds(event*60)}}
                            min={0}
                            max={30}
                            step={5}
                            
                            />
                    </div>
                </div>

                

            </div>

        )
    }
   
