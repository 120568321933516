import './App.css';
import Timer from './components/Timer/Timer';
import { useState, useEffect } from 'react';





function App() {
  const [workTime, setWorkTime] = useState(45);
  const [breakTime, setBreakTime] = useState(15);

  useEffect(() => {
    if (window.location.href == "https://produc-timer-lpmonaghan06-yahoocom.vercel.app/"){
      window.location.href = 'https://productimer.com';
    }
  }, []);

  return (
    <div className="App">
      <Timer initMinutes={1} initSeconds={30}/>

    </div>
  );
}

export default App;
