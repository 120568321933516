import React from "react";
import GitHub from "./Github";
import './Footer.css';

export default function Footer(){
    return(
        <div className='footerRowDiv'>
                <a href='https://www.github.com/slyguy5646' target="_blank" className='githubButton'>
                    <GitHub/>
                </a>
            
        </div>
    )
}